
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { InputDataLogin } from '../domainModel/InputDataLogin';
import { InputDataPulicAPI } from '../domainModel/InputDataPulicAPI';
import { InputDataForUserDetails } from '../domainModel/InputDataForUserDetails';
import { SessionService } from './SessionService';
import { ResponseLogin } from '../domainModel/ResponseLogin';
import { User } from '../domainModel/User';


@Injectable({
  providedIn: 'root'
})

export class PublicApiService {

  constructor(private sessionService: SessionService, private http: HttpClient) {
  }

  rootPath = environment.apiServiceRootURL;

  updateUserDetails(user: User) {
    //let restAPI = this.rootPath + 'updateUserDetails';
let restAPI = 'http://localhost:8083/invoke/' + 'updateUserDetails';

    let str: string
    str = JSON.stringify(user);
    let encoded = btoa(str);
    let finaldata = new InputDataPulicAPI();
    finaldata.oAuthToken = this.sessionService.oAuthToken;
    finaldata.base64Inputdata = encoded;

    return this.http.post<String>(restAPI, JSON.parse(JSON.stringify(finaldata)));

  }


  getUserDetails(serEMail: string) {
    let restAPI = this.rootPath + 'getUserDetails';
    //let restAPI = 'http://localhost:8083/invoke/' + 'getUserDetails';
    let input = new InputDataForUserDetails();
    input.username = serEMail;

    let str: string
    str = JSON.stringify(input);
    let encoded = btoa(str);
    let finaldata = new InputDataPulicAPI();


    finaldata.oAuthToken = this.sessionService.oAuthToken;
    finaldata.base64Inputdata = encoded;
   // console.log(str)
    //console.log(encoded)
    return this.http.post<User>(restAPI, JSON.parse(JSON.stringify(finaldata)));

  }

  login(userEMail: string, password: string) {
    let restAPI = this.rootPath + 'login';
    let input = new InputDataLogin();
    input.password = password;
    input.username = userEMail;
    let str: string
    str = JSON.stringify(input);
    let encoded = btoa(str);
    let finaldata = new InputDataPulicAPI();
    finaldata.oAuthToken = "tbd";
    finaldata.base64Inputdata = encoded;

    return this.http.post<ResponseLogin>(restAPI, JSON.parse(JSON.stringify(finaldata)));

  }

}
